<template>
  <TooltipModalBlock :title="t('bonuses.tootlipModalConditions')">
    <TooltipModalBlockItem
      :termin="t('bonuses.depositMinSum')"
      :description="minDepositAmount"
    />
  </TooltipModalBlock>

  <TooltipModalBlock
    v-if="isParametersBlockVisible"
    :title="t('bonuses.tooltipModalParameters')"
  >
    <TooltipModalBlockItem
      v-for="(item, index) in maxRewardBlocks"
      :key="index"
      :termin="item.termin"
      :description="item.description"
    />
    <TooltipModalBlockItem
      v-if="freespinAmountInAppCurrency"
      :termin="t('bonuses.freespinModalAmount')"
      :description="freespinAmountInAppCurrency"
    />

    <TooltipModalBlockItem
      v-for="(item, index) in reloadParameters"
      :key="index"
      :termin="item.termin"
      :description="item.description"
    />
  </TooltipModalBlock>

  <TooltipModalBlock
    v-if="rollingMultiplier && !isMultiBonus"
    :title="t('bonuses.tooltipModalWager')"
    data-t="multiplier-block"
  >
    <TooltipModalBlockItem :termin="t('bonuses.modalRolling')">
      x{{ rollingMultiplier }}
    </TooltipModalBlockItem>
    <TooltipModalBlockItem
      v-if="formattedRollingMinBetRate"
      :termin="t('bonuses.tooltipModalSportMin')"
      :description="formattedRollingMinBetRate"
    />
    <TooltipModalBlockItem
      v-if="formattedRollingMaxBetRate"
      :termin="t('bonuses.tooltipModalSportMax')"
      :description="formattedRollingMaxBetRate"
    />
    <TooltipModalBlockItem
      v-if="filteredCategoriesNames?.length"
      :termin="t('bonuses.freespinModalCasino')"
    >
      <div class="column">
        <span v-for="category in filteredCategoriesNames" :key="category">
          {{ category }}
        </span>
      </div>
    </TooltipModalBlockItem>
  </TooltipModalBlock>
</template>

<script setup lang="ts">
import { useRollingData } from '../../../composables/useRollingData'
import TooltipModalBlock from '../../TooltipModal/TooltipModalBlock.vue'
import TooltipModalBlockItem from '../../TooltipModal/TooltipModalBlockItem.vue'
import type { DepositBonus } from '../../../types'
import { useDepositTooltip } from '../useDepositTooltip'

interface Props {
  bonus: DepositBonus
}

const props = defineProps<Props>()

const { t } = useI18n()

const { bonus: depositBonus } = toRefs(props)
const {
  minDepositAmount,
  maxRewardBlocks,
  depositBonusRollingSettings,
  isParametersBlockVisible,
  reloadParameters,
  freespinAmountInAppCurrency,
  isMultiBonus,
} = useDepositTooltip(depositBonus)

const {
  rollingMultiplier,
  filteredCategoriesNames,
  formattedRollingMinBetRate,
  formattedRollingMaxBetRate,
} = useRollingData(depositBonusRollingSettings)
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
}
</style>
