<template>
  <TooltipModalBlock :title="blockTilte">
    <TooltipModalBlockItem
      :termin="t('bonuses.depositMinSum')"
      :description="minDepositAmount"
    />
    <template v-if="isParametersBlockVisible">
      <TooltipModalBlockItem
        v-for="(item, index) in maxRewardBlocks"
        :key="index"
        :termin="item.termin"
        :description="item.description"
      />
      <TooltipModalBlockItem
        v-if="freespinAmountInAppCurrency"
        :termin="t('bonuses.freespinModalAmount')"
        :description="freespinAmountInAppCurrency"
      />
      <TooltipModalBlockItem
        v-for="(item, index) in reloadParameters"
        :key="index"
        :termin="item.termin"
        :description="item.description"
      />
    </template>
  </TooltipModalBlock>
</template>

<script setup lang="ts">
import TooltipModalBlock from '../../TooltipModal/TooltipModalBlock.vue'
import TooltipModalBlockItem from '../../TooltipModal/TooltipModalBlockItem.vue'
import type { ExtendedDepositBonus } from '../../../types'
import { useDepositTooltip } from '../useDepositTooltip'

interface Props {
  bonus: ExtendedDepositBonus
}

const props = defineProps<Props>()

const { t } = useI18n()

const { bonus: depositBonus } = toRefs(props)

const {
  minDepositAmount,
  maxRewardBlocks,
  isParametersBlockVisible,
  reloadParameters,
  freespinAmountInAppCurrency,
  isMultiBonus,
  filteredMultiBonuses,
} = useDepositTooltip(depositBonus, { isStepBonus: true })

const blockTilte = computed(() => {
  const titles = filteredMultiBonuses.value.map(
    (item) => `${item?.text ?? ''} ${item?.amountWithCurrency ?? ''}`,
  )
  const titlesCombinations = titles.join(' + ')
  const postfix = isMultiBonus.value ? '' : t('bonuses.toDeposit')

  return `${titlesCombinations} ${postfix}`
})
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
}
</style>
